import React, { Component } from 'react'
import { translate } from 'react-i18next'
import { compose, graphql } from 'react-apollo'
import { Container, Card, CardBody, CardHeader, Row, Col } from 'reactstrap'
import Moment from 'react-moment'
import { Queries } from '../PublicAssetDetail/PublicAssetDetailGQL'
import { withRouter, Redirect } from 'react-router-dom'
import Spinner from '../../base/Spinner'
import Consts from '../../constants'
import Message from '../shared/message'
import { Config } from '../../config'
import UrlRedirect from '../UrlRedirect/UrlRedirect'

class SelfTestedPublicAssetDetail extends Component {

    handleLogin(assetId) {
        window.location.href = `/login${assetId !== '' ? '/' + assetId + '/' : ''}?self=true`
    }

    render() {
        const { t
        } = this.props  
    
        const hasQueryStarted = this.props.isAssetMigratedQuery || null
        const isQueryLoading =  this.props.isAssetMigratedQuery && this.props.isAssetMigratedQuery.loading                              
    
        const isAssetMigratedData = this.props.isAssetMigratedQuery.isAssetMigrated    
        const data = isAssetMigratedData ? isAssetMigratedData.caveosAsset : null;
        const assetId = data && data.assetIdParam ? data.assetIdParam : ''

        if (!hasQueryStarted) {
            return <Spinner />
        }
        else if (isQueryLoading) {
            return <Spinner />
        }
        //redirect to elektra only if asset exists in elektra
        else if (isAssetMigratedData && isAssetMigratedData.useElektra && isAssetMigratedData.elektraId)
        {
            const electraURL =`${Config.elektraHttp}account/assets/${isAssetMigratedData.assetNumber}/latest-public-test-result`
            return <UrlRedirect searchParams={[]} url={electraURL} />          
        }

        //Check if asset is self provisioned
        //Check if self provisioned test has been retested by OMS
        //Return NOT Found component or asset component based on these checks
        //Check if license is avaiable for public asset

        else {
            if (data && data.id) {
                if (data.selfTested) {
                    const { testSuccessful } = data

                    if (data.licensedModules && data.licensedModules.indexOf(Consts.PUBLICASSETPAGE) === -1) {
                        return (
                            <main className="main">
                                <Container fluid>
                                    <div className="max-width">
                                        <Row className="flex items-center mv4">
                                            <Col className="" md="12" xl="8">
                                                <React.Fragment>
                                                    <Card>
                                                        <CardHeader>
                                                            <h3>{t('assetDetail.assetId')}: {data.id} SELF PROVISIONED</h3>
                                                        </CardHeader>
                                                        <CardBody>
                                                            <Row>
                                                                <Col sm="12" md="4" className="mb4">
                                                                    <Row className="mb4">
                                                                        <Col>
                                                                            <p className="silver ttu f7 pmb-3">{t('assetDetail.assetType')}</p>
                                                                            <span>{data.assetType}</span>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col>
                                                                            <p className="silver ttu f7 pmb-3">{t('assetDetail.manufacturer')}</p>
                                                                            <span >{data.assetManufacturer}</span>
                                                                        </Col>
                                                                    </Row>

                                                                </Col>
                                                                <Col sm="12" md="4" className="mb4">

                                                                    <p className="silver ttu f7 pmb-3">{t('assetDetail.assetLocation')}</p>
                                                                    <span className="ttc">
                                                                        {
                                                                            ((data.assetSite === '-') ? '' : data.assetSite + " ") +
                                                                            ((data.assetBuilding === '-') ? '' : data.assetBuilding + " ") +
                                                                            ((data.assetFloor === '-') ? '' : data.assetFloor + " ") +
                                                                            ((data.assetRoom === '-') ? '' : data.assetRoom)
                                                                        }
                                                                    </span>
                                                                </Col>
                                                                <Col sm="12" md="4" className="mb-4">
                                                                    <p className="silver ttu f7 pmb-3">{t('assetDetail.operator')}</p>
                                                                    <p className="m pmb-3">{data.operatorName}</p>
                                                                    {
                                                                        data.operatorAddress.map((address, i) => <p key={i} className="gray pmb-3 ttc">{address}</p>)
                                                                    }
                                                                </Col>
                                                            </Row>
                                                        </CardBody>
                                                    </Card>
                                                    <Card>
                                                        <CardHeader>
                                                            <Row>
                                                                <Col sm="12" md="6" className="mb4">
                                                                    <h3 className="">{t('assetDetail.testDetail')}</h3>
                                                                </Col>
                                                                <Col lg="2"></Col>
                                                                <Col sm="12" md="12" lg="12" xl="4" className="mt1">
                                                                    <h3>
                                                                        <i className={"h1 pr-4 align-middle" + (testSuccessful ? ' icon-new-check-1 text-success ' : ' icon-new-warning-1 text-danger ')}></i>
                                                                        <span className={(testSuccessful ? 'text-success' : 'text-danger')}>{testSuccessful ? t('assetDetail.testSuccessfull') : t('assetDetail.testFailed')}</span>
                                                                    </h3>
                                                                </Col>
                                                            </Row>
                                                        </CardHeader>

                                                        <CardBody>
                                                            <Row sm="12" md="4">
                                                                <Col>
                                                                    <Row>
                                                                        <Col className="mb4">
                                                                            <Row className="mb4">
                                                                                <Col>
                                                                                    <div>
                                                                                        <p className="silver pmb-3 ttu">{t('assetDetail.testDate')}</p>
                                                                                        <span>
                                                                                            <Moment format="D.MM.YYYY" >{data.testDate}</Moment>
                                                                                        </span>
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col>
                                                                                    <p className="silver ttu pmb-3">{t('assetDetail.nextTestDate')}</p>
                                                                                    <span>
                                                                                        <Moment format="D.MM.YYYY" >{data.nextTestDate}</Moment>
                                                                                    </span>
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                                <Col sm="12" md="4" className="mb4">
                                                                    <p className="silver pmb-3 ttu">{t('assetDetail.testBasis')}</p>
                                                                    <span className="normal">{data.testRegulation}</span>
                                                                </Col>
                                                                <Col sm="12" md="4" className="mb4">
                                                                    <p className="silver pmb-3 ttu">{t('assetDetail.testServiceProvider')}</p>
                                                                    <p className="pmb-3">OMS Prüfservice GmbH</p>
                                                                    <p className="pmb-3">Mühlstraße 90</p>
                                                                    <p className="pmb-3">73547 Lorch-Waldhausen</p>

                                                                    <img src="/static/media/oms-logo.8271baaa.png" width="76" alt="OMS" className="mt1" />
                                                                    <p className="ttu f7"  >Prüfservice</p>

                                                                </Col>

                                                            </Row>
                                                        </CardBody>
                                                    </Card>
                                                </React.Fragment>

                                            </Col>
                                        </Row>
                                    </div>
                                </Container>
                            </main>
                        )
                    } else {

                        const buttonText = t('assetDetail.assetNotFoundSelfProvisioned.loginSubmit')
                        const text = t('assetDetail.noLicense')
                        return (

                            <Message icon={"lock"} text={text}
                                buttonText={buttonText}
                                assetId={assetId}
                                handleLogin={this.handleLogin} />
                        )
                    }

                }
                else {
                    return (
                        <Container>
                            <h1>NOT TESTED BY OMS</h1>
                        </Container>
                    )
                }

            }
            const buttonText = t('assetDetail.assetNotFoundSelfProvisioned.loginSubmit')
            const text = t('assetDetail.assetNotFoundSelfProvisioned.title')
            return (
                <Message icon={"clock"} text={text}
                    buttonText={buttonText}
                    assetId={assetId}
                    handleLogin={this.handleLogin} />
            )
        }
    }
}

export default translate('assetDetail', { wiat: true })(compose(
    withRouter,
    graphql(Queries.PUBLICASSETDETAIL_QUERY, {
        name: 'assetDetailQuery',
        options: ({ match }) => {
            return (
                {
                    variables: {
                        assetId: match.params.id,
                    },
                })
        },
    }),
    graphql(Queries.ISASSETMIGRATED_QUERY, {
        name: 'isAssetMigratedQuery',
        options: ({ match }) => {
            return (
                {
                    variables: {
                        assetNumber: match.params.id,
                    },
                })
        },
    })
)(SelfTestedPublicAssetDetail))

