
import React, { Component } from 'react'
import { ApolloProvider } from 'react-apollo'
import { I18nextProvider, translate } from 'react-i18next'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import './App.css'
// Styles
// CoreUI Icons Set
import '@coreui/icons/css/coreui-icons.css'
// Import Flag Icons Set
import 'flag-icon-css/css/flag-icon.min.css'
// Import Font Awesome Icons Set
import 'font-awesome/css/font-awesome.min.css'
// Import Simple Line Icons Set
import 'simple-line-icons/css/simple-line-icons.css'
// Import Main styles for this application
import './scss/style.css'
import 'tachyons'

//Config Setup
import { Config, ReactApolloClient, i18n } from './config'

// Pages
import {
  LoginPage,
  UserActivation,
  PasswordReset,
  Page404,
  Page500,
  LoginFaq,
  DefaultLayout,
  PublicAssetDetail,
  StaffActivation,
  StaffMemberActivationConfirmed,
  SearchPage,
  SelfTestedPublicAssetPage,
  PdfViewer

} from './views/Pages'

if (Config.env !== 'production') {
  localStorage.setItem('debug', 'oms.customer.portal:*')
}

class App extends Component {

  render() {
    return (
      <I18nextProvider i18n={i18n}>
        <ApolloProvider client={ReactApolloClient.client}>
          {/* Default Main Router */}
          <BrowserRouter>
            <Switch>
              <Route
                path="/pdf/viewer/"
                render={props => <PdfViewer {...props} />}
              />
              <Route
                path="/search"
                component={SearchPage}
              />
              <Route
                exact
                path="/assets/auth/s/:id"
                component={SelfTestedPublicAssetPage}
              />
               <Route
                exact
                path="/assets/auth/m/:id"
                component={ props => <PublicAssetDetail isMechanical={true}  {...props } />}
              />
              <Route
                exact
                path="/assets/auth/im/:id"
                component={ props => <PublicAssetDetail isMachinery={true}  {...props } />}
              />
              <Route
                exact
                path="/assets/auth/:id"
                component={PublicAssetDetail}
              />
              <Route
                exact
                path="/staff"
                component={StaffMemberActivationConfirmed}
              />
              <Route
                exact
                path="/login/:asset"
                name="Login Page"
                component={LoginPage}
              />
              <Route
                exact
                path="/login"
                name="Login Page"
                component={LoginPage}
              />
              <Route
                exact
                path="/useractivation/:link"
                name="User Activation"
                component={UserActivation}
              />
              <Route
                exact
                path="/staffactivation/:link"
                name="Staff Activation"
                component={StaffActivation}
              />
              <Route
                exact
                path="/resetpassword"
                name="Reset Password"
                component={PasswordReset}
              />
              <Route
                exact
                path="/404"
                name="Page 404"
                component={Page404}
              />
              <Route
                exact
                path="/500"
                name="Page 500"
                component={Page500}
              />
              <Route
                exact
                path="/loginfaq"
                name="Help"
                component={LoginFaq}
              />
              <Route
                path="/"
                name="Home"
                component={DefaultLayout}
              />
            </Switch>
          </BrowserRouter>
        </ApolloProvider>
      </I18nextProvider>
    )
  }
}

export default translate()(App);