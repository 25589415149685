import i18n from './i18n'
import {ReactApolloClient, UserSnap, GoogleAnalytics, GoogleTagManager} from './integrations'

const env = process.env

const Config = {
  env: env.REACT_APP_ENVIRONMENT,
  faqCount: env.REACT_APP_CUSTOMER_PORTAL_FAQ_COUNT,
  loginFaqCount: env.REACT_APP_CUSTOMER_PORTAL_LOGIN_FAQ_COUNT,
  launchDarklyKey: env.REACT_APP_LAUNCHDARKLY_KEY,
  wootricKey: env.REACT_APP_WOOTRIC_KEY,
  elektraHttp: env.REACT_APP_ELEKTRA_ENDPOINT_HTTP,
  gqlHttp: env.REACT_APP_GQL_ENDPOINT_HTTP ,
  userSnapGlobalKey: env.REACT_APP_GLOBAL_USERSNAP_KEY,
  userSnapKey: env.REACT_APP_USERSNAP_KEY,
  omsHttp: env.REACT_APP_OMS_SITE_HTTP
}

export {Config, ReactApolloClient, UserSnap, GoogleAnalytics, i18n, GoogleTagManager}
