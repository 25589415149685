import gql from 'graphql-tag'

const PUBLICASSETDETAIL_QUERY = gql`
query($assetId: String!){
    publicAssetDetail(assetId: $assetId){
        assetType,
        assetManufacturer,
        id,
        testDate,
        nextTestDate,
        operatorName,
        operatorAddress,
        operatorPostCode,
        operatorCity,
        assetSite,
        assetBuilding,
        assetFloor,
        assetRoom,
        testResult,
        testSuccessful,
        testRegulation,
        assetSafetyClass,
        selfTested,
        assetIdParam,
        licensedModules,
    }
}
`

const ISASSETMIGRATED_QUERY = gql`
query($assetNumber: ID!){
    isAssetMigrated(assetNumber: $assetNumber){
      assetNumber,   
      useElektra,
      caveosAsset{
        assetType,
        assetManufacturer,
        id,
        testDate,
        nextTestDate,
        operatorName,
        operatorAddress,
        operatorPostCode,
        operatorCity,
        assetSite,
        assetBuilding,
        assetFloor,
        assetRoom,
        testResult,
        testSuccessful,
        testRegulation,
        assetSafetyClass,
        selfTested,
        assetIdParam,
        licensedModules
      }
    }
}
`

const Queries = {
    PUBLICASSETDETAIL_QUERY,
    ISASSETMIGRATED_QUERY
}

const Mutations = {}

export {Queries, Mutations}