import React, { Component } from 'react'
import { translate } from 'react-i18next'
import { compose, graphql } from 'react-apollo'
import { Container, Card, CardBody, CardHeader, Row, Col, Button } from 'reactstrap'
import Moment from 'react-moment'

import { Queries } from '../PublicAssetDetailGQL'
import { withRouter } from 'react-router-dom'
import Spinner from '../../../base/Spinner'

import Consts from '../../../constants'
import Message from '../../shared/message'

import { Config } from '../../../config'
import UrlRedirect from '../../UrlRedirect/UrlRedirect'

class PublicAssetDetail extends Component {

  handleLogin(assetId) {
    window.location.href = `/login/${assetId}`
  }

  render() {
    const { t } = this.props

    const {isMachinery, isMechanical} = this.props
    const hasQueryStarted = this.props.isAssetMigratedQuery || null
    const isQueryLoading =  this.props.isAssetMigratedQuery && this.props.isAssetMigratedQuery.loading                              

    const isAssetMigratedData = this.props.isAssetMigratedQuery.isAssetMigrated  
    const data = isAssetMigratedData ? isAssetMigratedData.caveosAsset : null;

    if (!hasQueryStarted) {
      return <Spinner />
    }
    else if (isQueryLoading) {
      return <Spinner />
    }
    else if (isAssetMigratedData && isAssetMigratedData.useElektra)
    {
        const electraURL =`${Config.elektraHttp}account/assets/${isAssetMigratedData.assetNumber}/latest-public-test-result`
        return <UrlRedirect searchParams={[]} url={electraURL} />          
    }
    else {

      if(isMachinery || isMechanical)
      {       
        return <UrlRedirect searchParams={[]} url={Config.omsHttp} />     
      }

      if (data && data.id) {
        const { testSuccessful, id } = data
        if (data.licensedModules && data.licensedModules.indexOf(Consts.PUBLICASSETPAGE) === -1) {
          return (
            <main className="main">
              <Container fluid>
                <div className="max-width">
                  <Row className="flex items-center mv4">
                    <Col className="" md="12" xl="8">
                      <React.Fragment>
                        <Card>
                          <CardHeader>
                            <h3>{t('assetDetail.assetId')}: {id}</h3>
                          </CardHeader>
                          <CardBody>
                            <Row>
                              <Col sm="12" md="4" className="mb4">
                                <Row className="mb4">
                                  <Col>
                                    <p className="silver ttu f7 pmb-3">{t('assetDetail.assetType')}</p>
                                    <span>{data.assetType}</span>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <p className="silver ttu f7 pmb-3">{t('assetDetail.manufacturer')}</p>
                                    <span >{data.assetManufacturer}</span>
                                  </Col>
                                </Row>

                              </Col>
                              <Col sm="12" md="4" className="mb4">

                                <p className="silver ttu f7 pmb-3">{t('assetDetail.assetLocation')}</p>
                                <span className="ttc">
                                  {
                                    ((data.assetSite === '-') ? '' : data.assetSite + " ") +
                                    ((data.assetBuilding === '-') ? '' : data.assetBuilding + " ") +
                                    ((data.assetFloor === '-') ? '' : data.assetFloor + " ") +
                                    ((data.assetRoom === '-') ? '' : data.assetRoom)
                                  }
                                </span>
                              </Col>
                              <Col sm="12" md="4" className="mb-4">
                                <p className="silver ttu f7 pmb-3">{t('assetDetail.operator')}</p>
                                <p className="m pmb-3">{data.operatorName}</p>
                                {
                                  data.operatorAddress.map((address, i) => <p key={i} className="gray pmb-3 ttc">{address}</p>)
                                }
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                        <Card>
                          <CardHeader>
                            <Row>
                              <Col sm="12" md="6" className="mb4">
                                <h3 className="">{t('assetDetail.testDetail')}</h3>
                              </Col>
                              <Col lg="2"></Col>
                              <Col sm="12" md="12" lg="12" xl="4" className="mt1">
                                <h3>
                                  <i className={"h1 pr-4 align-middle" + (testSuccessful ? ' icon-new-check-1 text-success ' : ' icon-new-warning-1 text-danger ')}></i>
                                  <span className={(testSuccessful ? 'text-success' : 'text-danger')}>{testSuccessful ? t('assetDetail.testSuccessfull') : t('assetDetail.testFailed')}</span>
                                </h3>
                              </Col>
                            </Row>
                          </CardHeader>

                          <CardBody>
                            <Row sm="12" md="4">
                              <Col>
                                <Row>
                                  <Col className="mb4">
                                    <Row className="mb4">
                                      <Col>
                                        <div>
                                          <p className="silver pmb-3 ttu">{t('assetDetail.testDate')}</p>
                                          <span>
                                            <Moment format="D.MM.YYYY" >{data.testDate}</Moment>
                                          </span>
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col>
                                        <p className="silver ttu pmb-3">{t('assetDetail.nextTestDate')}</p>
                                        <span>
                                          <Moment format="D.MM.YYYY" >{data.nextTestDate}</Moment>
                                        </span>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </Col>
                              <Col sm="12" md="4" className="mb4">
                                <p className="silver pmb-3 ttu">{t('assetDetail.testBasis')}</p>
                                <span className="normal">{data.testRegulation}</span>
                              </Col>
                              <Col sm="12" md="4" className="mb4">
                                <p className="silver pmb-3 ttu">{t('assetDetail.testServiceProvider')}</p>
                                <p className="pmb-3">OMS Prüfservice GmbH</p>
                                <p className="pmb-3">Mühlstraße 90</p>
                                <p className="pmb-3">73547 Lorch-Waldhausen</p>

                                <img src="/static/media/oms-logo.8271baaa.png" width="76" alt="OMS" className="mt1" />
                                <p className="ttu f7"  >Prüfservice</p>

                              </Col>

                            </Row>
                            <Row>
                              <Col>
                                <div className="text-center">
                                  <Button onClick={() => this.handleLogin(id)} color="primary">{t('assetDetail.assetNotFoundSelfProvisioned.loginSubmit')}</Button>
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </React.Fragment>

                    </Col>
                  </Row>
                </div>
              </Container>
            </main>
          )
        } else {
          const buttonText = t('assetDetail.assetNotFoundSelfProvisioned.loginSubmit')
          const text = t('assetDetail.noLicense')
          return (

              <Message icon={"lock"} text={text}
                  buttonText={buttonText}
                  assetId={id}
                  handleLogin={this.handleLogin} />
          )
        }
      } else {
        return (
          <main className="main">
            <Container fluid>
              <div className="">
                <Row className="mv4">
                  <Col md="3"></Col>
                  <Col md="6">
                    <div className="bg-white pv6 ph3 text-center">
                      <svg width="150" height="150" viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M74.9375 12.5C40.4375 12.5 12.5 40.5 12.5 75C12.5 109.5 40.4375 137.5 74.9375 137.5C109.5 137.5 137.5 109.5 137.5 75C137.5 40.5 109.5 12.5 74.9375 12.5ZM75 125C47.375 125 25 102.625 25 75C25 47.375 47.375 25 75 25C102.625 25 125 47.375 125 75C125 102.625 102.625 125 75 125Z" fill="#333333" fill-opacity="0.38" />
                        <path d="M78.125 43.75H68.75V81.25L101.562 100.938L106.25 93.25L78.125 76.5625V43.75Z" fill="#333333" fill-opacity="0.38" />
                      </svg>
                      <div className="gray">
                        <h1>{t('assetDetail.assetNotFound.title')}</h1>
                        <p>{t('assetDetail.assetNotFound.description.line1')}</p>
                        <p>{t('assetDetail.assetNotFound.description.line2')}</p>
                        <p>{t('assetDetail.assetNotFound.description.line3-1')} <a href="https://oms-pruefservice.de/">{t('assetDetail.assetNotFound.description.line3-2')}</a> {t('assetDetail.assetNotFound.description.line3-3')}</p>
                      </div>
                    </div>
                  </Col>
                  <Col md="3"></Col>
                </Row>
              </div>
            </Container>
          </main>
        )
      }
    }
  }
}

export default translate('assetDetail', { wiat: true })(compose(
  withRouter,
  graphql(Queries.PUBLICASSETDETAIL_QUERY, {
    name: 'assetDetailQuery',
    options: ({ match }) => {
      return (
        {
          variables: {
            assetId: match.params.id,
          },
        })
    },
  }),
  graphql(Queries.ISASSETMIGRATED_QUERY, {
      name: 'isAssetMigratedQuery',
      options: ({ match }) => {
          return (
              {
                  variables: {
                      assetNumber: match.params.id,
                  },
              })
      },
  })
)(PublicAssetDetail))

